import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const WrapperFormItem = styled(Form.Item)`
	height: max-content;
	width: 100%;
	margin-bottom: 10px;

	.ant-select {
		min-height: 40px !important; 
		border-radius: 5px;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;

		.ant-select-selector{
			min-height: 40px;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #000000 !important;

			.ant-select-selection-item{
				display: flex;
    		align-items: center;
			}
		}
	}

	.ant-form-item-row{
		display: flex;
    flex-direction: column;
	}

	.ant-form-item-label{
		text-align: start;
	}

	.ant-select-disabled{
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #000000 !important;
	}

	.select__menu {
		z-index: 10;
	}

	.select__control {
		border: ${({ validateStatus, theme }) =>
			validateStatus === 'error'
				? `1px solid ${theme.error_ant} !important`
				: ''};
		box-shadow: ${({ validateStatus }) =>
			validateStatus === 'error' ? 'none' : ''};
	}

	.ant-form-item-children-icon {
		display: none;
	}

	.select__dropdown-indicator {
		color: hsl(0, 0%, 60%);
	}

	label::after{
		content: "" !important;
	}
`;

const WrapperLabel = styled.div`
	width: 100%;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 30px;
	color: #6D737A;
`;

function FormSelect({
	label,
	name,
	rules,
	defaultValue = '',
	wrapperProps,
	options = [],
	placeholder,
	...rest
}) {
	const { control } = useFormContext();
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});
	const [trans] = useTranslation('common');
	const [t] = useTranslation('student');
	const opts = useMemo(() => {
		if (t) {
			return options.map((opt) => ({ ...opt, label: t(opt.label) }));
		}
		return options;
	}, [options, t]);

	

	return (
		<WrapperFormItem
			{...wrapperProps}
			label={label && <WrapperLabel>{label}</WrapperLabel>}
			validateStatus={error ? 'error' : ''}
			help={error?.message}
		>
			<Select
				placeholder={t(placeholder)}
				notFoundContent={() => trans('noOption')}
				options={opts}
				value={value}
				onChange={onChange}
				{...rest}
			/>
		</WrapperFormItem>
	);
}

export default FormSelect;
