/* eslint-disable react/prop-types */
import { Form, Input } from "antd";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

const WrapperFormItem = styled(Form.Item)`
  height: max-content;
  width: 100%;
  margin-bottom: 10px;

  .ant-input {
    min-height: 40px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* :-webkit-autofill {
		background-color: white !important;
		color: #000000 !important;
		} */
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #686e7c;
    -moz-text-fill-color: #686e7c;
    -o-text-fill-color: #686e7c;
    -ms-text-fill-color: #686e7c;
  }
  input:focus-visible {
    outline: none;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-input-disabled {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000 !important;
  }

  .input-group {
    flex-wrap: nowrap !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: transparent !important;
  }

  .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: rgba(0, 0, 0, 0.85);
  }

  /* .ant-input-affix-wrapper:hover{
		border-color:	#d9d9d9 !important;
	} */

  .ant-form-item-label {
    font-size: 1.16rem;
    overflow: unset;
    white-space: unset;
    .ant-form-item-no-colon {
      height: 100%;
    }
  }
  label::after {
    content: "" !important;
  }

  .form-control:disabled {
    background-color: #f4f4f4 !important;
  }
`;

const WrapperLabel = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 30px;
  color: #6d737a;
`;

function FormInput({
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  disabled,
  ...rest
}) {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <WrapperFormItem
      {...wrapperProps}
      label={label && <WrapperLabel>{label}</WrapperLabel>}
      validateStatus={error ? "error" : ""}
      help={error?.message}
    >
      <Input
        className="form-control"
        onChange={onChange}
        value={value}
        {...rest}
        disabled={disabled}
      />
    </WrapperFormItem>
  );
}

export default FormInput;
