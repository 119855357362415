import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const WrapperBtn = styled(Button)`
  background: #E7E3E8;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #000000;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    color: #000000 !important;
    border-color: #E7E3E8 !important;
    opacity: 0.7;
  }
`
function NormalButton({ children, ...props }) {
  return (
    <WrapperBtn  {...props}>
      {children}
    </WrapperBtn>
  )
}

export default NormalButton
