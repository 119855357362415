import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

const WrapperBtn = styled(Button)`
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    color: #FFFFFF !important;
    border-color: transparent !important;
    opacity: 0.7;
  }

  &:disabled{
    background: #E7E3E8 !important;
    color: #000000 !important;
  }
`

function FailureBtn({ children, type, ...props }) {
  return (
    <WrapperBtn  {...props} type={type}>
      {children}
    </WrapperBtn>
  )
}

export default FailureBtn
