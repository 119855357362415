import styled from 'styled-components';
import { Footer, Header } from 'Layouts';
import LoadingModal from 'Pages/LoadingModal';

const Wrapper = styled.div`
	.children{
		height: calc(100vh - 6.6rem - 15rem);
	}
`;

function Loading() {
	return (
		<Wrapper className="">
			<Header />
				<div className='children'></div>
				<LoadingModal/>
			<Footer />
		</Wrapper>
	);
}

export default Loading;
