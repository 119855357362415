import React, { useMemo } from 'react';
import { Form, DatePicker, ConfigProvider } from 'antd';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ja'
import locale from 'antd/es/date-picker/locale/ja_JP'
const WrapperFormItem = styled(Form.Item)`
	height: max-content;
	width: 100%;
	margin-bottom: 10px;

	.ant-picker {
		min-height: 38px;
		border-radius: 4px;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;

		input{
			&:disabled{
				color: #000 !important;
			}
		}
	}

	.ant-form-item-label {
		font-size: 14px;
		overflow: unset;
		white-space: unset;
		.ant-form-item-no-colon {
			height: 100%;
		}
	}

	// wrap button outside to fix auto close calendar when click outside input
	button#popup-container {
		border: none;
		padding: 0;
		background-color: transparent;
		width: 100%;
		.ant-picker {
			width: 100%;
			height: 40px;
		}
	}
`;

const WrapperLabel = styled.div`
	width: 100%;
	font-size: 13px;
`;

function FormDatePicker({
	label,
	name,
	rules,
	defaultValue = '',
	wrapperProps,
	wrapperStyles,
	forceError,
	hideError,
	useDate,
	setTime,
	time,
	...rest
}) {
	const { control } = useFormContext();
	const {
		field: { onChange, value },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});
	const valueProps = useMemo(
		() => (useDate ? { value } : {}),
		[useDate, value]
	);

	const rangeTime = useMemo(() => time, [time])
	
	const disabledDate = (current) => {
		if (current.isBefore(dayjs(), 'day')) {
			return true;
		}
		
		if (name === "startTime") {
			const oneYearBeforeEndTime = dayjs(rangeTime.endTime).subtract(1, "year");
			const dayAfterEndTime = dayjs(rangeTime.endTime).subtract(1, "days");

   			return current.isBefore(oneYearBeforeEndTime, 'day') ||  current.isAfter(dayAfterEndTime,'day')
		}
		else {
			const oneYearAfterStartTime = dayjs(rangeTime.startTime).add(1, "year");
			const dayBeforeEndTime = dayjs(rangeTime.startTime).add(1, "day");

   			return current.isAfter(oneYearAfterStartTime, 'day') || current.isBefore(dayBeforeEndTime,'day')
		}
	};
	
	return (
		<WrapperFormItem
			{...wrapperProps}
			label={label && <WrapperLabel>{label}</WrapperLabel>}
			help={!hideError && error?.message}
			validateStatus={error || forceError ? 'error' : ''}
			style={wrapperStyles}
		>
			<button
				id="popup-container"
				type="button"
			>
				<ConfigProvider
					getPopupContainer={() =>
						document.getElementById('popup-container')
					}
				>
					<DatePicker
						onChange={(date, dateString) => {
							useDate ? onChange(date) : onChange(dateString)
							setTime({
								...rangeTime,
								[`${name}`]: date
							})
							}
						}
						locale={locale}
						allowClear={false}
						format={"YYYY/MM/DD"}
						disabledDate={disabledDate}
						value={dayjs(rangeTime[`${name}`])}
						{...rest}
						{...valueProps}
					/>
				</ConfigProvider>
			</button>
		</WrapperFormItem>
	);
}

export default FormDatePicker;
